import React from "react"

const Breadcrumbs = ({ title, description }) => {
  return (
    <div className="container-fluid migasdepan">
      <div className="container">
        <h1 data-sal="slide-up">{title}</h1>
        <h6 data-sal="slide-up" data-sal-delay="200">
          <i className="las la-quote-left"></i>
          {description}
          <i className="las la-quote-right"></i>
        </h6>
      </div>
    </div>
  )
}

export default Breadcrumbs
