import React from "react"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import ContactForm from "../components/ContactForm/ContactForm"

export default () => {
  return (
    <>
      <ContactForm />
    </>
  )
}
